<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <v-container class="location-overview">
      <ul v-if="selectedItemsPicker.length">
        <li v-for="(item, i) in selectedItemsPicker">
          {{ item.title }}
        </li>
      </ul>
    </v-container>
    <item-picker
      dialog
      service-name="locations"
      title-property="location"
      button-label="itemPicker.buttonLabel.locations"
      button-icon="mdi-map-marker-plus"
      select-available
      :selected-items.sync="selectedItemsPicker"
    />
  </td>
</template>

<script>

import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import ItemPicker from '@/components/ItemPicker/ItemPicker.vue'

export default {
  name: 'ChecklistCellTypeLocation',
  components: {
    CustomDialog,
    ItemPicker
  },
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      columnWidth:  300,
      selectedItemsPicker: (() => {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        let value = builder.getCellValue(index, this.cell, this.clone)
        try {
          if (typeof value === 'string') {
            value = JSON.parse(value)
          }
        } catch (ex) {
          value = []
        }
        const result = []
        if (Array.isArray(value)) {
          for (let i = 0, entry; (entry = value[i]); i++) {
            if (entry.hasOwnProperty('id')) {
              result.push({
                id: parseInt(entry.id) || 0,
                title: entry.label || ''
              })
            }
          }
        }
        return result
      })(),
      dialog: {
        active: false
      }
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    }
  },
  watch: {
    selectedItemsPicker: function(newValue, oldValue) {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const value = []
      if (Array.isArray(newValue)) {
        for (let i = 0, entry; (entry = newValue[i]); i++) {
          if (entry.hasOwnProperty('id')) {
            value.push({
              id: entry.id,
              label: entry.title || entry.label || ''
            })
          }
        }
      }

      let previousValue = builder.getCellValue(index, this.cell, this.clone)
      try {
        if (typeof previousValue === 'string') {
          previousValue = JSON.parse(previousValue)
        }
      } catch (ex) {
        previousValue = []
      }

      const result = builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      }) || {}

      if (result.abort) {
        const vlength = value.length
        const pvLength = previousValue.length
        if (vlength > pvLength) {
          this.selectedItemsPicker.splice(
            this.selectedItemsPicker.length - 1,
            1
          )
        } else if (vlength < pvLength) {
          for (let item, i = 0; (item = previousValue[i++]); ) {
            if (!newValue.filter(x => x.id === item.id).length) {
              this.selectedItemsPicker.push({
                id: item.id,
                title: item.label
              })
              break
            }
          }
        }
      }
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    }
  }
}
</script>

<style>

.checklist-viewer .cell_type_location .location-overview {
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin: 0 auto;
}

.checklist-viewer .cell_type_location .location-overview > ul {
    display: inline-block;
    text-align: left;
}

</style>
